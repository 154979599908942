import React, { useEffect, useState } from 'react';
import { faHome as fasHome } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Head from 'next/head';

export default function Custom404 () {
  const [ referer, setReferer ] = useState( null );
  useEffect( () => {
    if ( document !== undefined ) {
      document.querySelector( '#__next' ).classList.add( 'h-100' )
    }
    if ( window !== undefined ) {
      setReferer( window.location.origin + '/' );
    }
  }, [referer]);

  return (
    <>
      <Head>
        <title>404 - Page not found</title>
      </Head>
      <section className="h-100">
        <div className="container h-100">
          <div className="row justify-content-center align-items-center h-100 text-center">
            <div className="col-12">
              <h1>The page you requested wasn&apos;t found!</h1>
              <div className="col-auto">
                <button className="btn btn-block btn-cons p-3 py-2" onClick={() => location.href = referer}>
                  <FontAwesomeIcon icon={fasHome} className="me-1 fs-16"/> Go back to home page...
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
